import axios from 'axios'
import router from '../router'
import { Loading, Message } from 'element-ui'
import { isEmpty } from './util';

function debounceApi(fn, wait) {
  let timerId = null
  let flag = true
  return function () {
    clearTimeout(timerId)
    if (flag) {
      fn.apply(this, arguments)
      flag = false
    }
    timerId = setTimeout(() => {
      flag = true
    }, wait)
  }
}

const authError = debounceApi((message) => {
  Message({
    message,
    type: 'warning'
  })
}, 1000)

axios.defaults.timeout = 20000
// axios.defaults.baseURL = BASE_URL // 已在webpack.DefinePlugin中声明
axios.defaults.baseURL = ''
let loadingInstance

// needLoadingRequestCount 用于合并同一页面下同时发生的post/get
let needLoadingRequestCount = 0

function showFullScreenLoading () {
  if (needLoadingRequestCount === 0) {
    startLoading()
  }
  needLoadingRequestCount++
}

function tryHideFullScreenLoading () {
  if (needLoadingRequestCount <= 0) return
  needLoadingRequestCount--
  if (needLoadingRequestCount === 0) endLoading()
}

function startLoading () {
  loadingInstance = Loading.service({
    lock: true,
    target: document.querySelector('.loading-area')
  })
}

function endLoading () {
  loadingInstance.close()
}

axios.interceptors.request.use(
  config => {
    // 除未登录状态下没有token状态，其余所有API交互方式默认header添加token
    const tokenId = localStorage.getItem('tokenId') || ''
    const userId = localStorage.getItem('userId') || ''
    const companyId = localStorage.getItem('companyId') || ''
    const companyLevel = localStorage.getItem('companyLevel') || ''
    /*if(!localStorage.getItem('tokenId')){
      router.push({
        name: 'Login',
      })
    }*/
    // 固定header中存在值
    const normalHeaderInfo = {
      // systemType: '100030',
      // sourceType: 'web',
    }
    if (!isEmpty(tokenId) || !isEmpty(userId) || !isEmpty(companyLevel)) {
      // 判断是否是upload模式，如果是则需要改为form-data格式
      config.headers = {
        'Content-Type': config.isUpload ? 'multipart/form-data' : 'application/json',
        tokenId,
        userId,
        ...normalHeaderInfo,
      };
      if (!isEmpty(companyId)) {
        config.headers = {
          ...config.headers,
          companyId
        };
      }
      if (!isEmpty(companyLevel)) {
        config.headers = {
          ...config.headers,
          companyLevel
        };
      }
    } else {
      config.headers = {
        'Content-Type': 'application/json',
        ...normalHeaderInfo,
      }
    }
    if (config.showLoading) {
      showFullScreenLoading()
    }
    return config
  },
  err => {
    tryHideFullScreenLoading()
    Message.error({ message: '请求超时！' })
    return Promise.reject(err)
  }
)

axios.interceptors.response.use(
  response => {
    // showLoading判断，当post结束后根据showLoading值关闭loading, 默认不添加时为true
    if (response.config.showLoading) {
      tryHideFullScreenLoading()
    }

    // 导出excel文件时，需要用到这个来获取文件名
    if(response.headers['content-disposition']){
      response.data.headers=response.headers
      console.log(response.data.headers) // 文件里获取时就可以用response.headers
    }

    // 预处理返回值，如果为500，600则为红色报错，其余非0情况为黄色警报
    if (response.data.code !== 0 && response.data.resultCode !== '1') {
      if (String(response.data.code).indexOf("50") !== -1 || String(response.data.code).indexOf("60") !== -1 || response.data.code < 0) {
        Message.error(response.data.msg)
      } else {
        if (response.data.code === -1) {
          // 开启了9089时跳9089的登录页，不开则跳9090的登录页
          router.push({
            path: "/login",
          })
        }
        if (response.data.msg != '鉴权失败' && response.data.resultCode !== '0' ){
         //Message.warning(response.data.msg || '未知错误');
        }
      }
    }
    return response
  },
  error => {
    tryHideFullScreenLoading()
    // token过期时返回登录页
    if(error.response.data.code === 401 && error.response.data.errorCode==="Unauthorized"){
      // localStorage.clear()
      // router.push({
      //   path: "/login",
      //   query: {
      //     redirect: router.currentRoute.fullPath
      //   }
      // })
      // Message.error({ message: '登录失效，请重新登录！' })
      authError('登录失效，请重新登录！') //解决同一页面多个接口登录失效，出现多个相同的提示信息
    } else {
      // 隐藏，查看详细错误的时候显示
      Message.warning(error)
    }
    return Promise.reject(error)
  }
)

/**
 * 封装get请求
 * @param url
 * @param params
 * @returns {Promise<any>}
 */
export function get(url, params = {}) {
  return new Promise((resolve, reject) => {
    axios.get(url, {
      params,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @param config
 * @returns {Promise<any>}
 */
export function post(url, data = {}, config = {
  showLoading: true,
  isUpload: false
}) {
  return new Promise((resolve, reject) => {
    axios.post(url, data, config)
      .then((response) => {
        resolve(response.data);
      }, (err) => {
        reject(err);
      });
  });
}

/**
 * 封装put请求
 * @param url
 * @param data
 * @param config
 * @returns {Promise<any>}
 */
export function put(url, data = {}, config = {
  showLoading: true,
  isUpload: false
}) {
  return new Promise((resolve, reject) => {
    axios.put(url, data, config)
      .then((response) => {
        resolve(response.data);
      }, (err) => {
        reject(err);
      });
  });
}

/**
 * 封装delete请求
 * @param url
 * @param params
 * @returns {Promise<unknown>}
 */
export function del(url, params = {}) {
  return new Promise((resolve, reject) => {
    axios.delete(url, { params })
      .then((response) => {
        resolve(response.data);
      }, (err) => {
        reject(err);
      });
  });
}
