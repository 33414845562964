import Vue from 'vue'
import VueRouter from 'vue-router'
const Login = () => import(/* webpackChunkName: 'login' */ '../views/login/Login')
const Layout = () => import(/* webpackChunkName: 'system' */ '../views/layout/Layout')
const Home = () => import(/* webpackChunkName: 'system' */ '../views/main/home/Home')
const Kanban = () => import(/* webpackChunkName: 'kanban' */ '../views/main/home/Kanban')
const enterpriseSiteScreen = () => import(/* webpackChunkName: 'screen' */ '../views/main/home/enterpriseSiteScreen')
const companyScreen = () => import(/* webpackChunkName: 'screen' */ '../views/main/home/companyScreen')
const test = () => import(/* webpackChunkName: 'system' */ '../views/main/home/test')
const Labor = () => import(/* webpackChunkName: 'labor' */ '../views/main/labor/Labor')
const Count = () => import(/* webpackChunkName: 'labor' */ '../views/main/labor/Count')
const Attendance = () => import(/* webpackChunkName: 'attendance' */ '../views/main/attendance/Attendance')
const Education = () => import(/* webpackChunkName: 'education' */ '../views/main/safety/education/Education')
const Meeting = () => import(/* webpackChunkName: 'meeting' */ '../views/main/safety/meeting/Meeting')
const Warning = () => import(/* webpackChunkName: 'warning' */ '../views/main/safety/warning/Warning')
const AttendanceRate = () => import(/* webpackChunkName: 'report' */ '../views/main/report/Attendance-rate')
const Wage = () => import(/* webpackChunkName: 'report' */ '../views/main/report/Wage')
const DangerSetting = () => import(/* webpackChunkName: 'warning' */ '../views/main/setting/Danger-setting')
const Upload = () => import(/* webpackChunkName: 'warning' */ '../views/main/upload/upload')

Vue.use(VueRouter)

const routes = [
  {
    path: '/upload',
    name: 'Upload',
    component: Upload,
    meta: {
      name: '上传模型文件'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/kanban',
    name: 'Kanban',
    component: Kanban
  },
  {
    path: '/enterpriseSiteScreen',
    name: 'enterpriseSiteScreen',
    component: enterpriseSiteScreen
  },
  {
    path: '/companyScreen',
    name: 'companyScreen',
    component: companyScreen
  },
  {
    path: '/test',
    name: 'test',
    component: test
  },
  {
    path: '/',
    name: 'Layout',
    component: Layout,
    children:[
      {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
          name: '首页'
        }
      },
      {
        path: '/labor',
        name: 'Labor',
        component: Labor,
        meta: {
          name: '劳务工人库'
        }
      },
      {
        path: '/count',
        name: 'Count',
        component: Count,
        meta: {
          name: '劳务人员统计'
        }
      },
      {
        path: '/attendance',
        name: 'Attendance',
        component: Attendance,
        meta: {
          name: '项目考勤统计'
        }
      },
      {
        path: '/education',
        name: 'Education',
        component: Education,
        meta: {
          name: '三级安全教育'
        }
      },
      {
        path: '/meeting',
        name: 'Meeting',
        component: Meeting,
        meta: {
          name: '每日晨会'
        }
      },
      {
        path: '/warning',
        name: 'Warning',
        component: Warning,
        meta: {
          name: '预警消息'
        }
      },
      {
        path: '/report/attendance',
        name: 'AttendanceRate',
        component: AttendanceRate,
        meta: {
          name: '考勤统计报表'
        }
      },
      {
        path: '/report/wage',
        name: 'Wage',
        component: Wage,
        meta: {
          name: '项目工资报表'
        }
      },
      {
        path: '/setting/danger',
        name: 'DangerSetting',
        component: DangerSetting,
        meta: {
          name: '危险工种设置'
        }
      },

    ]
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
