import Api from './base';
import {
  del, get, post, put,
} from '../utils/axio';

export default {
  /**
   * 字典下拉框
   * @param data
   */
  listSystem(data){
    return get(`${Api.system}/dict/selector`, {...data});
  },
  /**
   * 系统权限：子系统列表
   */
  listSubsystem(data){
    return get(`${Api.system}/subSystem`, {...data})
  },
  
  // /**
  //  * 字典下拉框
  //  * @param data
  //  */
  // listProjectSystem(data){
  //   return get(`${Api.common}/project/system/dict/selector`, {...data});
  // },
  /**
   * 查询用户关联的所有公司 新
   * @param userId
   */
  listCommonCompanys(userId){
    return get(`${Api.common}/companyData/companys`, {userId});
  },
  queryMenuTree(){
    return get(`${Api.common}/authorityData/menuTreeList`, {});
  },
  /**
   * 告警配置、告警记录列表权限控制 data{url,name}
   * @param data
   */
  listAuthorityData(data){
    return get(`${Api.authorityData}/checkOtherAuth`, {...data});
  },
};
