import Api from './base';
import {
  del, get, post, put,
} from '../utils/axio';

export default {

  /**
   * 获取资质资格类型
   */
  listCredentialCertTypeJsonData() {
    return get(`${Api.orgCommonJson}/certType`, {});
  },

  /**
   * 获取资质资格状态变更原因
   */
  listCredentialCauseJsonData() {
    return get(`${Api.orgCommonJson}/cause`, {});
  },

  /**
   * 行政区划三级联动
   */
  listRegion() {
    return get(`${Api.orgCommonJson}/region`, {});
  },

  /**
   * 获取参建单位的参建类型
   */
  listBuildType() {
    return get(`${Api.orgCommonJson}/buildType`, {});
  },

  /**
   * 获取设备分类和设备种类
   */
   listDeviceCategory() {
    return get(`${Api.orgCommonJson}/deviceCategory`, {});
  },

  // /**
  //  * 获取报警信息
  //  * @param data
  //  */
  // getAlarmMessageList(jsonData) {
  //   return post(`/deviceApi/op/video/device/getAlarmMessageList`, jsonData );
  // },
};
