import Vue from 'vue'
import api from './api' // 已区分get, put, post, del

// import {
//   registerMicroApps,
//   start
// } from 'qiankun'
import App from './App.vue'
import router from './router'
import store from './store'
import { post, put, get } from './utils/axio'
import {
  unique,
  debounce,
  isRepeat,
  isEmpty,
  getAgeFromIdCard,
  collapseWidth,
  unCollapseWidth
} from './utils/util'


import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './icons/iconfont/iconfont.css'
import './styles/style.css'

import 'amfe-flexible'
import 'amfe-flexible/index.js'
// import VueAMap from 'vue-amap';
// import BaiduMap from 'vue-baidu-map'
import BaiduMap from '@/components/vue-baidu-map'

import moment from 'moment'; //导入模块
moment.locale('zh-cn'); //设置语言 或 moment.lang('zh-cn');
Vue.prototype.$moment = moment;//赋值使用

//定义全局过滤器实现日期格式化
Vue.filter("format", function(input, fmtstring) {
  // 使用momentjs这个日期格式化类库实现日期的格式化功能
  return moment(input).format(fmtstring);
});



//导入字体文件
import '../src/assets/fonts/dsfont.css'
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts

// 将自动注册所有组件为全局组件
import dataV from '@jiaminghi/data-view'
Vue.use(dataV)

// 全局过滤器
import * as filters from './filters'
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

Vue.config.productionTip = false

Vue.use(ElementUI)

Vue.prototype.$api = api
Vue.prototype.$post = post
Vue.prototype.$get = get
Vue.prototype.$put = put
Vue.prototype.$unique = unique
Vue.prototype.$debounce = debounce
Vue.prototype.$isRepeat = isRepeat
Vue.prototype.$isEmpty = isEmpty
Vue.prototype.$getAgeFromIdCard = getAgeFromIdCard
Vue.prototype.$collapseWidth = collapseWidth // 菜单收缩后的菜单宽度
Vue.prototype.$unCollapseWidth = unCollapseWidth // 菜单展开后的菜单宽度
Vue.prototype.$echarts = echarts

// Vue.use(VueAMap)
// // 初始化vue-amap
// VueAMap.initAMapApiLoader({
//     // 高德key
//     key: '6fe8b83cb504eec48068e8c8cf3b1b38',
//     // 插件集合 （插件按需引入）
//     plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor','AMap.DistrictSearch','AMap.Geocoder'],
//     uiVersion: '1.0.11' // 版本号
//   });

Vue.use(BaiduMap, {
  ak: 'y9elAYQcACRxfvQg7keXD4YNEEgupGMb',

})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
