import { isEmpty } from '../utils/util'
const moment = require('moment')
moment.suppressDeprecationWarnings = true

// 格式化时间戳为年月日时分秒
const dateTimeFormat = (timeStamp) => {
    if (isEmpty(timeStamp)) {
      return timeStamp;
    }
    return moment(timeStamp).format('YYYY-MM-DD HH:mm:ss');
};
// 格式化时间戳为年月日
const dateFormat = (timeStamp) => {
    if (isEmpty(timeStamp)) {
        return timeStamp;
    }
    return moment(timeStamp).format('YYYY-MM-DD');
};
// 格式化时间戳为时分秒
const timeFormat = (timeStamp) => {
    if (isEmpty(timeStamp)) {
        return timeStamp;
    }
    return moment(timeStamp).format('HH:mm:ss');
};

// 性别展示
const sexFormat = (sex) => {
    const sexMap = new Map([
        [1, '男'],
        [2, '女'],
    ]);
    return sexMap.get(sex || 1);
};

// 状态展示
const stateFormat = (state) => {
    const stateMap = new Map([
        [1, '启用'],
        [2, '禁用'],
    ]);
    return stateMap.get(state);
};
// 报警级别
const alarmLevelFormat = (level) => {
    const alarmLevelMap = new Map([
        ["4", 'A级'],
        ["3", 'B级'],
    ]);
    return alarmLevelMap.get(level);
};
// 塔吊告警类型对应
const craneAlarmFormat = (type) => {
  const alarmTypeMap = new Map([
    [0, '无告警'],
    [1, '系统预警'],
    [2, '系统报警'],
    [3, '违章操作'],
    [4, '传感器报警']
  ]);
  return alarmTypeMap.get(type);
};

// 低级别报警
const alarmAllLevelFormat = (level) => {
    const alarmLevelMap = new Map([
        ["4", 'A级'],
        ["3", 'B级'],
        ["2", 'C级'],
        ["1", 'D级'],
    ]);
    return alarmLevelMap.get(level);
};
// 报警推送方式
// 报警级别
const pushChannelFormat = (pushChannel) => {
    const pushChannelMap = new Map([
        ["1", '短信+App'],
        [1, '短信+App']
    ]);
    return pushChannelMap.get(pushChannel);
};

// 不良行为、良好行为的部门级别
const departTypeIDFormat = (state) => {
    const stateMap = new Map([
        [1, "国家级"],
        [2, "省级"],
        [3, "市级"],
        [4, "区县级"]
    ]);
    return stateMap.get(state);
};
// 库存状态
const stockFormat = (state) => {
    const stateMap = new Map([
        [0, "未入库"],
        [1, "库存中"],
        [2, "已出库"]
    ]);
    return stateMap.get(state);
};

//  审核状态
const AuditStatusFormat = (state) => {
    const stateMap = new Map([
        [0, "未申请"],
        [1, "审核中"],
        [2, "审核通过"],
        [3, '驳回']
    ]);
    return stateMap.get(state);
};
// 企业启用状态 companyStatus
const companyStatusFormat = (status) => {
  const statusMap = new Map([
      [0, "启用"],
      [1, "禁用"]
  ]);
  return statusMap.get(status);
};
// 企业经营状态 businessStatus
const businessStatusFormat = (state) => {
    const stateMap = new Map([
        [1, "启用"],
        [2, "禁用"]
    ]);
    return stateMap.get(state);
};
// 市场主体类别 marketType
const marketTypeFormat = (state) => {
    const stateMap = new Map([
        ['1', "建设单位"],
        ['2', "勘察"],
        ['3', "设计"],
        ['4', "施工"],
        ['5', "监理"],
    ]);
    return stateMap.get(state);
};
// 项目状态 projectStatus
const projectStatusFormat = (state) => {
    const stateMap = new Map([
        [1, "筹备"],
        [2, "立项"],
        [3, "在建"],
        [4, "完工"],
        [5, "停工"],
    ]);
    return stateMap.get(state);
};
// 企业归属类型
const attributionTypeFormat = (state) => {
    const stateMap = new Map([
        [1, "内部企业"],
        [2, "外部企业"]
    ]);
    return stateMap.get(state);
};
// 账号类型
const userTypeFormat = (state) => {
    const stateMap = new Map([
        ['1', "管理员"],
        ['2', "普通用户"]
    ]);
    return stateMap.get(state);
};

// 服务状态展示
const serverStatusFormat = (serverStatus) => {
    const stateMap = new Map([
        [0, '有效'],
        [1, '过期'],
    ]);
    return stateMap.get(serverStatus);
};

// 设备归属展示
const deviceEscheatageFormat = (deviceEscheatage) => {
    const deviceEscheatageMap = new Map([
        [0, '租赁'],
        [1, '自有'],
    ]);
    return deviceEscheatageMap.get(deviceEscheatage);
};

// 设备状态展示
const deviceStatusFormat = (deviceStatus) => {
    const deviceStatusMap = new Map([
        [1, '运行'],
        [2, '维修'],
        [3, '报废'],
        [4, '保养'],
    ]);
    return deviceStatusMap.get(deviceStatus);
};
// 工资发放模式
const wagePaymentModeFormat = (state) => {
    const stateMap = new Map([
        [1, "按月"],
        [2, "按天"]
    ]);
    return stateMap.get(state);
};
// 门户账号类型
const portalUserTypeFormat = (type) => {
    const typeMap = new Map([
        [1, "超级管理员"],
        [2, "管理员"],
        [3, "普通人员"]
    ]);
    return typeMap.get(type);
};
// 项目账号类型
const projectUserTypeFormat = (state) => {
    const stateMap = new Map([
        [1, "管理员"],
        [2, "普通用户"]
    ]);
    return stateMap.get(state);
};
// 立项级别
const approvalLevelFormat = (state) => {
    const stateMap = new Map([
        [1, "部级"],
        [2, "省级"],
        [3, "地市级"],
        [4,"区县级"]
    ]);
    return stateMap.get(state);
};
// 建设规模
const constructionScaleFormat = (state) => {
    const stateMap = new Map([
        [1, "大型"],
        [2, "中型"],
        [3, "小型"]
    ]);
    return stateMap.get(state);
};
// 建设性质
const constructionNatureFormat = (state) => {
    const stateMap = new Map([
        [1,"新建"],
        [2,"改建"],
        [3,"扩建"],
        [4,"恢复"],
        [5,"拆建"],
        [6,"拆除"],
        [7,"其他"]
    ]);
    return stateMap.get(state);
};
// 证件类型
const certTypeFormat = (state) => {
    const stateMap = new Map([
        [1,"身份证"]
    ]);
    return stateMap.get(state);
};

// 资产设备台账：单位(1.台/2。个/3。辆)
const unitFormat = (deviceEscheatage) => {
    const deviceEscheatageMap = new Map([
        ['1', '台'],
        ['2', '个'],
        ['3', '辆']
    ]);
    return deviceEscheatageMap.get(deviceEscheatage);
};

// 模板发布状态
const templateTypeFormat = (type) => {
    const typeMap = new Map([
        [1, '保存'],
        [2, '发布'],
        [3, '历史']
    ]);
    return typeMap.get(type);
};
// 整改检查状态
const rectifyStatusFormat = (state) => {
    const stateMap = new Map([
        [1, '未整改'],
        [2, '未验收'],
        [3, '已完成']
    ]);
    return stateMap.get(state);
};
// 整改检查验收结果
const rectifyResultFormat = (state) => {
    const stateMap = new Map([
        [1, '合格'],
        [2, '不合格']
    ]);
    return stateMap.get(state);
};


// 巡检周期
const cycleFormat = (state) => {
    const stateMap = new Map([
        [1, '每日'],
        [2, '每周'],
        [3, '每月'],
        [4, '每季'],
        [5, '每年']
    ]);
    return stateMap.get(state);
};
// 巡检任务状态
const inspectTaskStatusFormat = (state) => {
    const stateMap = new Map([
        [1, '未开始'],
        [2, '巡检中'],
        [3, '已完成'],
        [4, '已过期']
    ]);
    return stateMap.get(state);
};
const deviceCategoryFormat = (category) => {
    const categoryMap = new Map([
        ['1', '大型机械设备'],
        ['2', '流动式机械设备'],
        ['3', '固定资产设备'],
    ])
    return categoryMap.get(category);
};
const resultFormat = (installationResult) => {
    const resultMap = new Map([
        [0, '成功'],
        [1, '失败'],
    ]);
    return resultMap.get(installationResult);
};
// 保养任务状态
const maintainTaskStatusFormat = (state) => {
    const stateMap = new Map([
        [1, '未开始'],
        [2, '保养中'],
        [3, '已完成'],
        [4, '已过期']
    ]);
    return stateMap.get(state);
};
// 接地类型
const landTypeFormat = (state) => {
    const stateMap = new Map([
        [1, '工作接地'],
        [2, '保护接地'],
        [3, '重复接地'],
        [4, '防雷接地'],
        [5, '防静电接地'],
        [6, '隔离接地'],
        [7, '屏蔽接地']
    ]);
    return stateMap.get(state);
};
// 接地测量状态
const messureStatusFormat = (state) => {
    const stateMap = new Map([
        [1, '未测量'],
        [2, '已测量'],
    ]);
    return stateMap.get(state);
};

// 合规检查状态
const complianceStateFormat = (state) => {
    const stateMap = new Map([
        ['1', '检查中'],
        ['2', '已完成']
    ]);
    return stateMap.get(state);
};

// 资质资格取得方式
const addTypeFormat = (state) => {
    const stateMap = new Map([
        [0, "无"],
        [1, "申请"],
        [2, "升级"],
        [3, "增项"],
        [4, "延续"],
        [5, "重新核定"],
        [5, "转移"]
    ]);
    return stateMap.get(state);
};
// 动火重点区域
const areaTypeFormat = (level) => {
    const areaTypeMap = new Map([
        [0, '普通区域'],
        [1, '立面动火'],
        [2, '受限空间'],
        [3, '机房'],
        [4, '加工区']
    ]);
    return areaTypeMap.get(level);
};

export {
    dateTimeFormat,
    dateFormat,
    timeFormat,
    sexFormat,
    stateFormat,
    alarmLevelFormat,
    alarmAllLevelFormat,
    pushChannelFormat,
    departTypeIDFormat,
    stockFormat,
    AuditStatusFormat,
    businessStatusFormat,
    marketTypeFormat,
    projectStatusFormat,
    attributionTypeFormat,
    userTypeFormat,
    serverStatusFormat,
    deviceEscheatageFormat,
    deviceStatusFormat,
    wagePaymentModeFormat,
    portalUserTypeFormat,
    projectUserTypeFormat,
    approvalLevelFormat,
    constructionScaleFormat,
    constructionNatureFormat,
    certTypeFormat,
    unitFormat,
    templateTypeFormat,
    rectifyStatusFormat,
    rectifyResultFormat,

    cycleFormat,
    inspectTaskStatusFormat,
    deviceCategoryFormat,
    resultFormat,
    maintainTaskStatusFormat,
    landTypeFormat,
    messureStatusFormat,
    complianceStateFormat,
    craneAlarmFormat,
    addTypeFormat,
    companyStatusFormat,
    areaTypeFormat
}
