/**
 * 跨应用路由跳转
 * @param href
 * @param title
 * @param stateObj
 */
 export function routerGo(href = '/', title = null, stateObj = {}) {
    // eslint-disable-next-line no-undef
    localStorage.setItem('system', JSON.stringify({
      href,
      title,
    }));
    // eslint-disable-next-line no-undef
    window.history.pushState(stateObj, title, href);
  }
/**
 * 路由监听
 * @param routerPrefix
 * @return {function(*): boolean}
 */
 export function genActiveRule(routerPrefix) {
    return (location) => location.pathname.startsWith(routerPrefix);
  }
  
/**
 * 去重+排序
 * @param a
 * @returns {Uint8Array}
 */
export function unique(a) {
  return a.concat().sort().filter(function(item, pos, ary) {
    return !pos || item !== ary[pos - 1]
  })
}

/**
 * 判断空字符串、null、undefined、空格、中文空格
 * @param obj
 * @return {boolean}
 */
export function isEmpty(obj) {
  if (obj === null) return true
  if (typeof obj === 'undefined') return true
  if (typeof obj === 'string') {
    if (obj === '') {
      return true
    }
    const reg = new RegExp("^([ ]+)|([　]+)$")
    return reg.test(obj)
  }
  if (typeof obj == 'number') {
    if (obj === 0) {
      return true
    }
  }
  return false
}

/**
 * 校验是否列表中有重复
 * @param arr
 */
export function isRepeat(arr) {
  let hash = {}
  for (let i in arr) {
    if (hash[arr[i]]) {
      return true
    }
    hash[arr[i]] = true
  }
  return false
}

/**
 * 通过身份证获取用户年龄
 * @param idCard
 */
export function getAgeFromIdCard(idCard) {
  const reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
  if (!reg.test(idCard) || idCard.length !== 18) {
    return 0;
  }
  const year = idCard.substring(6, 10);
  const month = idCard.substring(10, 12);
  const day = idCard.substring(12, 14);
  const today = new Date();
  const currentMonth = today.getMonth() + 1;
  const currentDay = today.getDate();
  let age = today.getFullYear() - year - 1;
  if (month < currentMonth || (month === currentMonth && day <= currentDay)) {
    age += 1;
  }
  return age;
}

let timeout = null
// 函数防抖，实现延迟触发效果
export function debounce (fn, wait) {
  if (timeout !== null) {
    clearTimeout(timeout)
  }
  timeout = setTimeout(fn, wait)
}

export const collapseWidth = 84
export const unCollapseWidth = 270
